// stocks/client/src/App.tsx

import React, { useState, useEffect, useRef } from 'react';
import StockChart from './components/StockChart';
import AllStocksChart from './components/AllStocksChart';
import BotDashboard from './components/BotDashboard';
import ContactDialog from './components/dialogs/ContactDialog';
import AboutPage from './pages/AboutPage';
import { 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Chip, 
  SelectChangeEvent, 
  Container, 
  Typography, 
  Box, 
  Grid,
  Paper,
  IconButton,
  Tooltip as MuiTooltip,
  Divider,
  AppBar,
  Toolbar,
  Menu,
  useScrollTrigger
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fetchStocks, fetchStockData } from './services/api';
import { Stock, StockData } from './types';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
    },
    secondary: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
    },
    background: {
      default: '#f8fafc',
      paper: '#ffffff',
    },
    text: {
      primary: '#1e293b',
      secondary: '#475569',
    },
    divider: '#e2e8f0',
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      letterSpacing: '-0.02em',
    },
    h4: {
      fontWeight: 500,
      letterSpacing: '-0.01em',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    }
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          boxShadow: '0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.04)',
          height: '70px',
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '100%',
          padding: '0 24px',
          '@media (min-width: 1200px)': {
            padding: '0 40px',
          },
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width: 1200px)': {
            paddingLeft: '40px',
            paddingRight: '40px',
          },
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: '0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.04)',
          border: '1px solid',
          borderColor: '#e2e8f0',
          transition: 'box-shadow 0.2s ease-in-out',
          '&:hover': {
            boxShadow: '0 4px 6px -1px rgba(0,0,0,0.08), 0 2px 4px -1px rgba(0,0,0,0.04)',
          }
        },
        elevation1: {
          boxShadow: '0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.04)',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '6px 16px',
          fontWeight: 500,
          textTransform: 'none',
          '&:hover': {
            transform: 'translateY(-1px)',
            transition: 'transform 0.2s ease-in-out',
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        }
      }
    }
  }
});

const getSentimentMessage = (timeRange: string, customStartDate: string, customEndDate: string) => {
  switch (timeRange) {
    case 'week':
      return 'Sentiment scores are calculated over the last 7 days including non-trading days.';
    case 'month':
      return 'Sentiment scores are calculated over the last month including non-trading days.';
    case 'custom':
      const start = new Date(customStartDate);
      const end = new Date(customEndDate);
      const days = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
      return `Sentiment scores are calculated over ${days} days including non-trading days.`;
    default:
      return 'Sentiment scores are calculated over the entire available period including non-trading days.';
  }
};

const App: React.FC = () => {
  const [timeRange, setTimeRange] = useState<string>('all');
  const [customStartDate, setCustomStartDate] = useState<string>('');
  const [customEndDate, setCustomEndDate] = useState<string>('');
  const [detailedTimeRange, setDetailedTimeRange] = useState<string>('all');
  const [detailedStartDate, setDetailedStartDate] = useState<string>('');
  const [detailedEndDate, setDetailedEndDate] = useState<string>('');
  const [stocks, setStocks] = useState<Stock[]>([]);
  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);
  const [stockData, setStockData] = useState<Record<string, StockData[]>>({});
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [showAboutPage, setShowAboutPage] = useState(false);
  const allStocksChartRef = useRef<any>(null);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        console.log('Loading initial data...');
        const stocksList = await fetchStocks();
        console.log('Fetched stocks:', stocksList.length);
        setStocks(stocksList);
        const stockDataResult = await fetchStockData();
        console.log('Fetched stock data for:', Object.keys(stockDataResult).length, 'stocks');
        setStockData(stockDataResult);
      } catch (error) {
        console.error("Failed to fetch initial data:", error);
      }
    };
    loadInitialData();
  }, []);

  const handleStockSelect = (event: SelectChangeEvent<string[]>) => {
    const selected = event.target.value as string[];
    console.log('Selected stocks:', selected);
    setSelectedStocks(selected);
  };

  const handleStockRemove = (stockToRemove: string) => {
    console.log('Removing stock:', stockToRemove);
    setSelectedStocks(selectedStocks.filter(stock => stock !== stockToRemove));
  };

  const handleTimeRangeChange = (event: SelectChangeEvent<string>) => {
    const range = event.target.value as string;
    console.log('Changed time range to:', range);
    setTimeRange(range);
  };

  const handleDetailedTimeRangeChange = (event: SelectChangeEvent<string>) => {
    const range = event.target.value as string;
    console.log('Changed detailed time range to:', range);
    setDetailedTimeRange(range);
  };

  const handleClearAllStocks = () => {
    console.log('Clearing all stocks');
    if (allStocksChartRef.current?.clearSelectedStocks) {
      allStocksChartRef.current.clearSelectedStocks();
    }
  };

  const handleExplore = async () => {
    try {
      console.log('Fetching updated stock data...');
      const data = await fetchStockData();
      console.log('Updated stock data received');
      setStockData(data);
    } catch (error) {
      console.error("Failed to fetch stock data:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar 
        position="sticky" 
        elevation={trigger ? 2 : 0}
        sx={{
          backdropFilter: 'blur(8px)',
          backgroundColor: alpha('#ffffff', trigger ? 0.95 : 1),
        }}
      >
        <Toolbar>
          <Container maxWidth="xl" disableGutters>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 3,
                height: '70px',
                cursor: 'pointer',
              }} 
                onClick={() => setShowAboutPage(false)}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2,
                }}>
                  <img 
                    src="/bernie-bot.png" 
                    alt="Bernie Bot Logo" 
                    height="40"
                    style={{ 
                      objectFit: 'contain',
                    }}
                  />
                  <Typography 
                    variant="h1" 
                    sx={{ 
                      background: 'linear-gradient(135deg, #1976d2 0%, #2e7d32 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: 600,
                      letterSpacing: '-0.02em',
                      fontSize: '1.8rem',
                    }}
                  >
                    Bernie-Bot
                  </Typography>
                </Box>
              </Box>

              <Box 
                sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 500,
                    letterSpacing: '-0.02em',
                  }}
                >
                  Intelligent Market Insights
                </Typography>
                <Box
                  component="div"
                  sx={{
                    background: 'linear-gradient(90deg, #1976d2 0%, #2e7d32 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontWeight: 500,
                    letterSpacing: '0.02em',
                    fontSize: '0.875rem',
                  }}
                >
                  AI-Driven Trading • Real-Time Sentiment Analysis
                </Box>
              </Box>

              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
                <Button
                  color="primary"
                  onClick={() => setShowAboutPage(true)}
                  startIcon={<InfoIcon />}
                  sx={{
                    borderRadius: '8px',
                    fontWeight: 500,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    }
                  }}
                >
                  About
                </Button>
                <Button
                  color="primary"
                  onClick={() => setShowContactDialog(true)}
                  startIcon={<ContactSupportIcon />}
                  sx={{
                    borderRadius: '8px',
                    fontWeight: 500,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    }
                  }}
                >
                  Contact
                </Button>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>

      {showAboutPage ? (
        <AboutPage />
      ) : (
        <Box sx={{ backgroundColor: 'background.default', minHeight: 'calc(100vh - 70px)', pb: 8 }}>
          <Container maxWidth="xl">
            <Box my={4}>
              <Grid container spacing={2} alignItems="center" mb={4}>
                <Grid item xs>
                  <Typography variant="h4" component="h2" fontWeight="500">
                    Market Analysis Dashboard
                  </Typography>
                </Grid>
                <Grid item>
                  <MuiTooltip title={getSentimentMessage(timeRange, customStartDate, customEndDate)} placement="left-start">
                    <IconButton>
                      <InfoOutlinedIcon color="primary" />
                    </IconButton>
                  </MuiTooltip>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <Box p={3} sx={{ minHeight: '600px' }}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 500 }}>
                        Market News Sentiment Analysis
                      </Typography>
                      <Grid container spacing={2} alignItems="center" mb={3}>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Date Range</InputLabel>
                            <Select
                              value={timeRange}
                              onChange={handleTimeRangeChange}
                              label="Date Range"
                            >
                              <MenuItem value="all">All Time</MenuItem>
                              <MenuItem value="week">Last Week</MenuItem>
                              <MenuItem value="month">Last Month</MenuItem>
                              <MenuItem value="custom">Custom Range</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {timeRange === 'custom' && (
                          <>
                            <Grid item xs={12} md={3}>
                              <TextField
                                fullWidth
                                type="date"
                                label="Start Date"
                                value={customStartDate}
                                onChange={(e) => setCustomStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                fullWidth
                                type="date"
                                label="End Date"
                                value={customEndDate}
                                onChange={(e) => setCustomEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={handleClearAllStocks}
                            startIcon={<CloseIcon />}
                            color="primary"
                          >
                            Clear All Stocks
                          </Button>
                        </Grid>
                      </Grid>
                      
                      <Box sx={{ 
                        height: '450px',
                        mt: 2,
                        '& .recharts-text, & .recharts-legend-item-text': {
                          fontSize: '0.7rem !important',
                        },
                        '& .recharts-legend': {
                          fontSize: '0.7rem !important',
                          maxHeight: '80px',
                          overflowY: 'auto',
                        }
                      }}>
                        <AllStocksChart 
                          ref={allStocksChartRef}
                          data={stockData} 
                          timeRange={timeRange}
                          customStartDate={customStartDate}
                          customEndDate={customEndDate}
                        />
                      </Box>

                      <Box mt={2}>
                        <Typography variant="body2" color="text.secondary">
                          * {getSentimentMessage(timeRange, customStartDate, customEndDate)}
                          {' '}Click on a data point to view details.
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Box my={6}>
                <Divider />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper elevation={0}>
                    <Box p={3}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 500 }}>
                        Stock Analysis Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Date Range</InputLabel>
                            <Select
                              value={detailedTimeRange}
                              onChange={handleDetailedTimeRangeChange}
                              label="Date Range"
                            >
                              <MenuItem value="all">All Time</MenuItem>
                              <MenuItem value="week">Last Week</MenuItem>
                              <MenuItem value="month">Last Month</MenuItem>
                              <MenuItem value="custom">Custom Range</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {detailedTimeRange === 'custom' && (
                          <>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                type="date"
                                label="Start Date"
                                value={detailedStartDate}
                                onChange={(e) => setDetailedStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                type="date"
                                label="End Date"
                                value={detailedEndDate}
                                onChange={(e) => setDetailedEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel>Select Stocks</InputLabel>
                        <Select
                          multiple
                          value={selectedStocks}
                          onChange={handleStockSelect}
                          label="Select Stocks"
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip 
                                  key={value} 
                                  label={value} 
                                  onDelete={() => handleStockRemove(value)}
                                  sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {stocks.map(stock => (
                            <MenuItem key={stock.symbol} value={stock.symbol}>{stock.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      
                      <Button 
                        fullWidth 
                        variant="contained" 
                        onClick={handleExplore} 
                        sx={{ 
                          mt: 2,
                          background: 'linear-gradient(135deg, #1976d2 0%, #2e7d32 100%)',
                          '&:hover': {
                            background: 'linear-gradient(135deg, #1565c0 0%, #2e7d32 100%)',
                          }
                        }}
                      >
                        Analyze Selected Stocks
                      </Button>
                    </Box>
                  </Paper>
                  {selectedStocks.map(stock => (
                    <Box mt={4} key={stock}>
                      <Paper elevation={0}>
                        <Box p={3}>
                          <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                            {stock} Sentiment Analysis
                          </Typography>
                          <Box height={300}>
                            <StockChart 
                              data={stockData[stock] || []} 
                              timeRange={detailedTimeRange}
                              customStartDate={detailedStartDate}
                              customEndDate={detailedEndDate}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper elevation={0}>
                    <Box p={3}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 500 }}>
                        Trading Bot Performance
                      </Typography>
                      <BotDashboard symbol={selectedStocks[0] || 'AAPL'} />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}

      <ContactDialog 
        open={showContactDialog} 
        onClose={() => setShowContactDialog(false)} 
      />

    </ThemeProvider>
  );
};

export default App;